import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import RulesDialog from "./RulesDialog";


const CustomRulesButton = styled(Button)((props) => ({
    borderRadius: "79px",
    color: props.theme.palette.secondary.main,
    fontWeight: "700",
    fontSize: "16px",
    letterSpacing: "0.2em",
    height: "48px",
    width: "100%",
    backgroundColor: props.theme.palette.primary.main
}));

export const Rules = () => {

    const [openRulesDialog, setOpenRulesDialog] = useState<boolean>(false);

    const handleOpenRulesDialog = () => {
        setOpenRulesDialog(true);
    }

    const handleCloseRulesDialog = () => {
        setOpenRulesDialog(false);
    }

    return (
        <>
            <CustomRulesButton variant="contained" onClick={handleOpenRulesDialog}>NORMAS</CustomRulesButton>
            <RulesDialog open={openRulesDialog} onClose={handleCloseRulesDialog} />
        </>
    )
}