import { styled } from "@mui/material/styles";
import { MainTableHeader } from "./MainTableHeader";
import ScoreTable from "./ScoreTable";
import { MainTableFooter } from "./MainTableFooter";

const MainTableWrapper = styled("div")((props) => ({
    color: props.theme.palette.secondary.main,
    textUnderlineOffset: "7px"
}));


export default function MainTable() {
    return (
        <MainTableWrapper>
            <MainTableHeader />
            <ScoreTable />
            <MainTableFooter />
        </MainTableWrapper>
    )
};
