import logo from '../assets/2024/ir-logo-footer.svg'
import isurus from '../assets/2024/powered-by.svg';
import { Grid } from '@mui/material';
import { styled } from "@mui/material/styles";
import { IsolatorWrapper, LogoAmdWrapper, LogoFuryWrapper, LogoHyperxWrapper, LogoIsurusWrapper, LogoSporedWrapper, LogoWrapper, LogoWrapperFooter } from '../components/Wrappers';

const FooterWrapper = styled("div")((props) => ({
    borderTop: `5px solid ${props.theme.palette.primary.main}`,
    backgroundColor: props.theme.palette.primary.main
}));

const SectionSeparatorWrapper = styled(Grid)((props) => ({
    [props.theme.breakpoints.up('md')]: {
        borderRight: `1px solid ${props.theme.palette.primary.main}`,
    },
    height: "71px"
}));



export function Footer() {
    return (
        <FooterWrapper>
            <Grid container spacing={2}
                alignItems="center"
                justifyContent="space-around">
                <Grid item xs={12} md={6}>
                    <Grid container
                        justifyContent={{xs: "center", md: "flex-start"}}
                        paddingLeft={{xs: "0px", md: "80px"}}
                        alignItems="center">
                        <LogoWrapperFooter src={logo} alt="logo" title='Ladder Race' />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container
                        justifyContent={{xs: "center", md: "flex-end"}}
                        paddingRight={{xs: "0px", md: "80px"}}
                        alignItems="center">
                            <a href="https://teamisurus.com/" target="_blank" rel="noreferrer">
                                <LogoIsurusWrapper src={isurus} alt="isurus" title='Isurus' />
                            </a>
                    </Grid>
                </Grid>
            </Grid>
        </FooterWrapper>
    )
};