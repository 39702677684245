import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';

interface IRulesDialogProps {
  open: boolean,
  onClose: Function
}
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const RulesSecTitleWrapper = styled("div")((props) => ({
  fontSize: '24px',
  fontWeight: '700',
  color: props.theme.palette.primary.main,
  fontStyle: 'italic'
}));

export const RulesDescWrapper = styled("div")((props) => ({
  fontSize: '18px',
  fontWeight: '500',
  color: props.theme.palette.primary.main,
  textAlign: 'justify'
}));

export default function RulesDialog(props: IRulesDialogProps) {
  const handleClose = () => {
    props.onClose()
  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="rules-dialog"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle sx={{ backgroundColor: 'secondary.main', color: 'primary.main', fontWeight: '500', fontSize: '30px', borderBottom: '0.15px solid grey' }}>NORMAS</DialogTitle>
      <DialogContent sx={{ backgroundColor: 'secondary.main' }}>
        <br />
        <RulesSecTitleWrapper><b>Formato</b></RulesSecTitleWrapper>
          Servidor: Latinoamérica Norte
          <br />
          Fechas: Ladders del 31 de julio al 20 de agosto, Gran Final 28 de agosto.
          <br />
          <br />

          <RulesDescWrapper>
            Competencia Ranked
            <br />
            ● 2 Ladders: High Elo y Low Elo 
            <br />
            ○ High Elo: de Retador a Diamante 4 durante el SET 11.
            <br />
            ○ Low Elo: de Esmeral 1 a Hierro 4 durante el SET 11, con un mínimo de 50 partidas jugadas durante el SET.
            <br />
            <br />
            ● 3 Semanas de competencia en el servidor de LAN del 31 de julio al 20 de agosto a las 20:00 Horas Ciudad de México.
            <br />
            ● Los 8 participantes mejores rankeados de la Ladder High Elo tras finalizar las 3 semanas de competencia clasifican a una Gran Final. 
            <br />
            ● En caso de que uno de los competidores no pueda participar de la Gran Final, se dará su lugar al siguiente mejor rankeado que pueda participar de la misma.
            <br />
            <br />
            Gran Final
            <br />
            ● 1 Ronda; 5 Partidas.
          </RulesDescWrapper>
          <br />

           


          <RulesSecTitleWrapper>Premiaciones</RulesSecTitleWrapper>
          <hr />
          <RulesDescWrapper>
            <b>Competencia Ranked</b>
            <br />
            <b>LOW ELO</b>
            <br />
            ● 1º Puesto: 50 USD.
            <br />
            ● 2º Puesto: 25 USD.
            <br />
            <br />
            <b>HIGH ELO</b>
            <br />
            ● 1º Puesto: 125 USD.
            <br />
            ● 2º Puesto: 75 USD.
            <br />
            ● 3º Puesto: 50 USD.
            <br />
            ● 4º Puesto: 25 USD.
            <br />
            <i>*Del 1º al 8 clasifican a la Gran Final</i>
            <br />
            <br />
            <b>Gran Final</b>
            <br />
            ● 1º Puesto: 200 USD
            <br />
            ● 2º Puesto:: 125 USD
            <br />
            ● 3º Puesto:: 75 USD
            <br />
            ● 4º Puesto: 50 USD
            <br />
          </RulesDescWrapper>

          <br />

          <RulesSecTitleWrapper>Terminos y Condiciones de las premiaciones</RulesSecTitleWrapper>
          <hr />
          <RulesDescWrapper>
            El pago indicado está expresado en dólares estadounidenses, es bruto y se realizará mediante una única transferencia bancaria o mediante paypal, en hasta 60 (sesenta) días hábiles de finalizada la competencia, en la cuenta que se indique oportunamente mediante el correo oficial designado para notificaciones por parte del JUGADOR, previa emisión y envío de una factura/invoice y documentos fiscales necesarios (W8, W8-E, W9 form) por parte del JUGADOR a la ORGANIZADORA con los datos de la respectiva cuenta bancaria.
            <br />
            <br />
            Los productos de patrocinadores de la competencia se entregarán en hasta 45 (cuarenta y cinco) días hábiles de finalizada la competencia, debiendo indicarse a tales fines, un domicilio de entrega de productos.
            <br />
            <br />
            Los productos virtuales dentro del juego se entregarán en hasta 30 (treinta) días hábiles de finalizada la competencia, debiendo indicarse la información que en su
            oportunidad solicite el Publisher del videojuego a tales efectos. 
            <br />
            <br />
          </RulesDescWrapper>
          <br />
          

          <RulesSecTitleWrapper>Instrucciones Gran Final</RulesSecTitleWrapper>
          <hr />
          <RulesDescWrapper>

            ● Ingreso a la sala
            <br />
            ○ Todos los jugadores serán invitados al lobby para iniciar la partida, siendo invitados por su Summoner Name.
            <br />
            <br />
            ● Iniciando la partida
            <br />
            ○ En el caso de que un jugador no se presente, los demás miembros de la sala deben esperar hasta que el tiempo de tolerancia termine (10 minutos).
            <br />
            ○ Todos los jugadores deben tomar una captura de pantalla de la sala antes de empezar la partida, asegurándose de que la lista de invitados sea visible. 
            <br />
            ○ Si todos los jugadores presentes se encuentran listos, el juego debe iniciar.
            <br />
            <br />

            ● Reportando resultados
            <br />
            ○ Una vez finalizada una partida,cada jugador sumará puntos.
            <br />
            ○ En caso de haber 2 o más lobbys en simultáneo, la segunda partida se usará formato suizo como medida de separación.unda partida
            <br />
            ○ El ganador y el segundo puesto deberán reportar una captura de pantalla.
            <br />
            <br />

            ● Tiempo entre partidas
            <br />
            ○ Al finalizar la partida, un jugador tendrá 10 minutos para reportarse a la siguiente partida, en caso de no hacerlo la partida comenzará sin él y se le asignará el 8º Puesto.
            <br />
            <br />

            <b>● Índice de Puntuación</b>
            <br />
            ○ 1º Puesto: 8 Puntos.
            <br />
            ○ 2º Puesto: 7 Puntos.
            <br />
            ○ 3º Puesto: 6 Puntos.
            <br />
            ○ 4º Puesto: 5 Puntos.
            <br />
            ○ 5º Puesto: 4 Puntos
            <br />
            ○ 6º Puesto: 3 Puntos.
            <br />
            ○ 7º Puesto: 2 Puntos.
            <br />
            ○ 8º Puesto: 1 Puntos.
            <br />
            <br />
          </RulesDescWrapper>

          <RulesSecTitleWrapper>Criterios de Desempate</RulesSecTitleWrapper>
          <hr />
          <RulesDescWrapper>
            <b>Competencia Ranked</b>
            <br />
            1. TOP 4 %.
            <br />
            2. TOP 1 %.
            <br />
            <br />

            <b>Gran Final</b>
            <br />
            1. Mayor cantidad de partidas finalizadas en 1º Lugar.
            <br />
            2. Mayor cantidad de partidas finalizadas dentro del Top 4.
            <br />
            3. Menor cantidad de partidas finalizadas en 8º Lugar.
            <br />
            4. Resultado de la última partida.
            <br />
            <br />
          </RulesDescWrapper>

          <RulesSecTitleWrapper>Cesión de Imagen e Propiedad Intelectual</RulesSecTitleWrapper>
          <hr />
          <RulesDescWrapper>
            El Participante licencia a la ORGANIZADORA el derecho de uso de su imágen relacionada con su participación en la Competencia, para su uso en cualquier medio (físico y/o electrónico) y para cualquier propósito (comercial, publicitario, institucional, cultural, artístico, filantrópico), en todo el mundo, especialmente para la producción de contenido, así como para la difusión y promoción de la Competencia, de la ORGANIZADORA y de los patrocinadores de la Competencia. Los derechos de imagen ahora licenciados incluyen el uso de imágen, retrato, caricatura, rostro, perfil, nombre, apellido, nickname, seudónimo, iniciales, autógrafo, firma y voz.
            <br />
            <br />
            El Participante cede y transfiere a la ORGANIZADORA todos los derechos de propiedad intelectual relacionados con el contenido producido por él el las actividades vinculadas a la Competencia, tales como, pero no limitado a, videos, audios, fotografías, entre otros a los que el Participante ha contribuido de algún modo.
            <br />
            <br />
          </RulesDescWrapper>
          

          <RulesSecTitleWrapper>Penalidades</RulesSecTitleWrapper>
          <hr />
          <RulesDescWrapper>
            En los casos de incumplimiento de las reglas de la Competencia y demás reglas legales aplicables, la ORGANIZADORA podrá aplicar las siguientes sanciones: (i) Advertencia; y/o (ii) Exclusión del Participante de la Competencia y pérdida de todos los beneficios y créditos ganados. 
            <br />
            <br />
            La ORGANIZADORA llamará al Participante para tratar un posible acto violatorio, con hasta 02 (dos) días de anticipación si posible, otorgando el derecho de réplica y manifestación sobre los actos a deliberar y que serán descritos en este aviso previo. Al final, la ORGANIZADORA decidirá la sanción a aplicar, considerando, en particular el grado de daño causado y el efecto generado en perjuicio de la Competencia y de los demás competidores. 
            <br />
            <br />
          </RulesDescWrapper>

      </DialogContent>
      <DialogActions sx={{ backgroundColor: 'secondary.main' }}>
        <Button onClick={handleClose} sx={{ color: 'primary.main', fontWeight: '700', fontSize: '16px' }}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}