import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

interface ICustomButtonProps {
    width?: string,
    status?: string
}
export const CustomButton = styled(Button)<ICustomButtonProps>((props) => ({
    border: `2px solid ${props.theme.palette.primary.main}`,
    borderRadius: "13px",
    color: props.status === "active" ? props.theme.palette.secondary.main : props.theme.palette.primary.main,
    fontWeight: "700",
    fontSize: "16px",
    letterSpacing: "0.2em",
    height: "40px",
    width: props.width ?? "160px",
    backgroundColor: props.status === "active" ? props.theme.palette.primary.main : props.theme.palette.secondary.main,
    "&:hover": {
        background: props.theme.palette.primary.dark,
        color: props.theme.palette.secondary.main,
    },
}));