import apoyan from '../assets/2024/apoyan.svg';
import omen from '../assets/2024/omen.svg';
import hyperx from '../assets/2024/hyperx.svg';
import liverpool from '../assets/2024/liverpool.svg';
import logo from '../assets/2024/logo-blanco.svg';
import { Grid } from '@mui/material';
import { styled } from "@mui/material/styles";
import { LogoApoyanWrapper, LogoHyperxWrapper, LogoLiverpoolWrapper, LogoOmenWrapper, LogoWrapper } from '../components/Wrappers';

const HeaderWrapper = styled("div")((props) => ({
    borderBottom: `5px solid ${props.theme.palette.secondary.main}`
}));

// const HeaderMenuItem = styled("div")<IMenuItemProps>((props) => ({
//     fontWeight: 700,
//     fontSize: "20px",
//     color: props.theme.palette.secondary.main,
//     textDecoration: props.active ? 'underline solid 3px' : '',
//     textUnderlineOffset: "7px",
//     cursor: "pointer"
// }));

export function Header() {
    // const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);

    // useEffect(() => {
    //     setMenuItems([
    //         { name: "ITEM 1", url: "", active: true },
    //         { name: "ITEM 2", url: "", active: false },
    //         { name: "ITEM 3", url: "", active: false }
    //     ]);
    // }, []);

    return (
        <HeaderWrapper>
            {/* <Grid container
                alignItems="center"
                justifyContent="center">
                <Grid item xs={1} />
                <Grid item xs={2}>
                    <Grid container
                        alignItems="center"
                        justifyContent="center">
                        <LogoWrapper src={logo} alt="logo" title='Ladder Race' />
                    </Grid>
                </Grid>
                <Grid item xs={7} />
                <Grid item xs={2} />
                <Grid item xs={4}>
                    <Grid container
                        spacing={4}
                        justifyContent="center"
                        alignItems="center">
                        {
                            menuItems.map(menuItem =>
                                <Grid item key={menuItem.name}>
                                    <HeaderMenuItem active={menuItem.active} title={menuItem.name}>{menuItem.name}</HeaderMenuItem>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
                <Grid item xs={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Grid container
                        justifyContent="flex-end"
                        alignItems="center">
                        <IsolatorWrapper src={isolator} alt="isolator" />
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid container
                alignItems="center"
                justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Grid container
                        alignItems="center"
                        justifyContent={{xs: "center", md: "flex-start"}}
                        marginLeft={{xs: "0px", md: "80px"}}>
                        <LogoWrapper src={logo} alt="logo" title='Ladder Race Omen Transcend 14' />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container
                        alignItems="center"
                        justifyContent="space-around">

                        <Grid item xs={12} md={3}
                            marginTop={{xs: "10px", md: "0px"}}>
                            <Grid container
                                alignItems="center"
                                justifyContent={{xs: "center", md: "flex-start"}}>
                                <LogoApoyanWrapper src={apoyan} alt="apoyan" title='Marcas aupiciantes' />
                            </Grid>
                        </Grid>

                        <Grid item xs={4} md={3}
                            marginTop={{xs: "10px", md: "0px"}}>
                            <Grid container
                                alignItems="center"
                                justifyContent="center">
                                <LogoOmenWrapper src={omen} alt="omen" title='Omen' />
                            </Grid>
                        </Grid>

                        <Grid item xs={4} md={3}
                            marginTop={{xs: "10px", md: "0px"}}>
                            <Grid container
                                alignItems="center"
                                justifyContent="center">
                                <LogoHyperxWrapper src={hyperx} alt="hyper-x" title='Hyper-x' />
                            </Grid>
                        </Grid>

                        <Grid item xs={4} md={3}
                            marginTop={{xs: "10px", md: "0px"}}>
                            <Grid container
                                alignItems="center"
                                justifyContent="center">
                                <LogoLiverpoolWrapper src={liverpool} alt="liverpool" title='Liverpool' />
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
        </HeaderWrapper>
    )
};
