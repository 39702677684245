import { styled } from "@mui/material/styles";

export const IsolatorWrapper = styled("img")((props) => ({
    width: "100%",
    maxWidth: "320px"
}));

export const LogoWrapper = styled("img")((props) => ({
    width: "90%",
    maxWidth: "400px",
    padding: "10px"
}));

export const LogoWrapperFooter = styled("img")((props) => ({
    width: "100%",
    maxWidth: "155px",
    padding: "20px"
}));

export const LogoIsurusWrapper = styled("img")((props) => ({
    width: "100%",
    maxWidth: "188px"
}));

export const LogoSponsorsWrapper = styled("img")((props) => ({
    width: "100%",
    maxWidth: "399px"
}));

export const LogoSporedWrapper = styled("img")((props) => ({
    width: "100%",
    maxWidth: "125px"
}));

export const LogoAmdWrapper = styled("img")((props) => ({
    width: "100%",
    maxWidth: "67px"
}));

export const LogoFuryWrapper = styled("img")((props) => ({
    width: "100%",
    maxWidth: "66px"
}));

//2024
export const LogoApoyanWrapper = styled("img")((props) => ({
    width: "90%",
    maxWidth: "114px",
    height: "100%",
    maxHeight: "30px"
}));

export const LogoOmenWrapper = styled("img")((props) => ({
    width: "90%",
    maxWidth: "196px",
    height: "100%",
    maxHeight: "30px"
}));

export const LogoHyperxWrapper = styled("img")((props) => ({
    width: "90%",
    maxWidth: "194px",
    height: "100%",
    maxHeight: "30px",
    marginTop: "4px"
}));

export const LogoLiverpoolWrapper = styled("img")((props) => ({
    width: "90%",
    maxWidth: "174px",
    height: "100%",
    maxHeight: "30px"
}));