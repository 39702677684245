import { Box, Checkbox, FormControlLabel, Grid, IconButton, InputBase, TableSortLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CustomButton } from "../../components/CustomButton";
import slidesshow from '../../assets/2024/slideshow.svg';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import Fuse from 'fuse.js';
import { Rules } from "./Rules";
import { mockData } from "./MockData";
import { rankList } from "./RankList";


interface ITableData {
    pos?: number,
    nick_name: string,
    is_streaming: boolean,
    twitch: string,
    twitch_avatar: string,
    summoner_avatar: string,
    elo: string,
    total_games: number,
    wins: number,
    losses: number,
    win_rate: string,
    tier: string,
    rank: string,
    league_points: number
}

interface ITableDataCollection {
    high: ITableData[],
    low: ITableData[],
}

const ScoreTableWrapper = styled("div")((props) => ({
    padding: "10px"
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary.main,
        fontSize: 15,
        fontWeight: 700,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    [`&.${tableCellClasses.body}`]: {
        color: theme.palette.primary.main,
        fontSize: 15,
        fontWeight: 700,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
    '&.MuiTableSortLabel-root': {
        color: 'inherit !important',
    },
    '&.MuiTableSortLabel-root:hover': {
        color: `${theme.palette.primary.light} !important`,
    },
    '&.Mui-active': {
        color: 'inherit !important',
    },
    '& .MuiTableSortLabel-icon': {
        color: 'inherit !important',
    }
}));

const StyledEloScore = styled('span')(({ theme }) => ({
    color: theme.palette.secondary.contrastText
}));

const UserLogoWrapper = styled("img")({
    width: "32px",
    height: "32px",
    marginRight: "10px",
    borderRadius: '30px'
});

interface ITwitchtStateProps {
    isStreaming: boolean
}
const TwitchLogoWrapper = styled("img")<ITwitchtStateProps>((props) => ({
    width: "24px",
    height: "24px",
    marginRight: "10px",
    filter: `invert(18%) sepia(24%) saturate(1169%) hue-rotate(347deg) brightness(103%) ${props.isStreaming ? 'contrast(109%)' : 'contrast(10%)'}`,
}));

const SlidesLogoWrapper = styled("img")<ITwitchtStateProps>((props) => ({
    width: "24px",
    height: "24px",
    marginRight: "10px",
    filter: `${props.isStreaming ? 'contrast(109%)' : 'contrast(10%)'}`,
}));


const LinkStatusWrapper = styled("a")<ITwitchtStateProps>((props) => ({
    color: props.isStreaming ? props.theme.palette.primary.main : props.theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 700,
    textDecoration: 'none',
    marginRight: "5px"
}));

const LinkWrapper = styled('a')(({ theme }) => ({
    color: theme.palette.primary.main
}));

const MAX_PLAYERS_SHOW: number = 15;

type SortDir = 'asc' | 'desc';

export default function ScoreTable() {
    const [filterValue, setFilterValue] = useState<string>("");
    const [playersAll, setPlayersAll] = useState<ITableData[]>([]);
    const [playersShow, setPlayersShow] = useState<ITableData[]>([]);
    const [showAll, setShowAll] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<keyof ITableData>('elo');
    const [sortDir, setSortDir] = useState<SortDir>('asc');
    const [activeTable, setActiveTable] = useState<string>('high');
    const [showOnlineOnly, setShowOnlineOnly] = useState<boolean>(false);

    useEffect(() => {
      getTableContent('high');
    }, []);

    const applyFilter = (value: string, collection: ITableData[], forceShowAll: boolean) => {
        setFilterValue(value);

        const options = {
            includeScore: true,
            keys: ['nick_name', 'twitch', 'elo'],
            threshold: 0.3
        };

        const fuse = new Fuse(collection, options);
        let filterResult: Fuse.FuseResult<ITableData>[] = fuse.search(value);

        let result: ITableData[] = filterResult.map(x => x.item);

        result = (!value || value === '') ? collection : result;

        result = showOnlineOnly ? result.filter(x => x.is_streaming) : result;

        if (!forceShowAll) setShowAll(!(result.length > MAX_PLAYERS_SHOW));

        setPlayersShow(result.slice(0, forceShowAll ? result.length : MAX_PLAYERS_SHOW));
    };

    const applySort = (resultCollection: ITableData[], sortAtt: keyof ITableData, dir: SortDir): ITableData[] => {
        return resultCollection.sort((a: ITableData, b: ITableData) => dir === 'asc' ? -rankSort(a, b) : rankSort(a, b));
    }

    const rankSort = (a: ITableData, b: ITableData) => {
        if (rankList[`${a.tier} ${a.rank}`.trim()] > rankList[`${b.tier} ${b.rank}`.trim()])
            return 1
        else if (rankList[`${a.tier} ${a.rank}`.trim()] < rankList[`${b.tier} ${b.rank}`.trim()])
            return -1
        else {
            if (a.league_points > b.league_points)
                return 1
            else if (a.league_points < b.league_points)
                return -1
            else
                if (parseInt(a.win_rate.replace('%', "")) > parseInt(b.win_rate.replace('%', "")))
                    return 1
                else if (parseInt(a.win_rate.replace('%', "")) < parseInt(b.win_rate.replace('%', "")))
                    return -1
                else
                    return 0
        }
    }

    useEffect(() => {
        applyFilter(filterValue, playersAll, false);
    }, [playersAll, sortDir, sortBy, showOnlineOnly]);

    const onClickShowAll = () => {
        setShowAll(true);
        applyFilter(filterValue, playersAll, true);
    };

    // const handleRequestSort = (property: keyof ITableData) => (event: React.MouseEvent<unknown>) => {
    //     const isAsc = sortBy === property && sortDir === 'asc';
    //     setSortDir(isAsc ? 'desc' : 'asc');
    //     setSortBy(property);

    //     let result: ITableData[] = applySort(mockData, sortBy, sortDir);
    //     result = result.map((res: ITableData, index: number) => {
    //         res.pos = isAsc ? index + 1 : result.length - index;
    //         return res
    //     });

    //     setPlayersAll(result)
    // };

    const onClickChangeTable = (table: string) => {
        setShowAll(false);
        setActiveTable(table);
        getTableContent(table);
    };

    const getTableContent = (table: string ) => {
        fetch('https://ladder.isurus.gg/api/summoners')
        .then(response => {
            return response.json()
        })
        .then((data: ITableDataCollection) => {
            let dataSelected : ITableData[] = table === 'high' ? data.high : data.low;

            let result: ITableData[] = applySort(dataSelected, sortBy, sortDir);
            result = result.map((res: ITableData, index: number) =>{ 
                res.pos = index + 1;
                return res
            });

            setPlayersAll(result);
            setSortBy('elo');
            setSortDir('asc');
        })


        // let dataSelected : ITableData[] = table === 'high' ? mockData.high as ITableData[] : mockData.low as ITableData[];

        // let result: ITableData[] = applySort(dataSelected, sortBy, sortDir);
        // result = result.map((res: ITableData, index: number) => {
        //     res.pos = index + 1;
        //     return res
        // });

        // setPlayersAll(result);
        // setSortBy('elo');
        // setSortDir('asc');
    }


    const handleChangeShowOnline = () => {
        setShowOnlineOnly(prevState => !prevState)
    }

    return (
        <ScoreTableWrapper>
            {/* <ButtonsSection /> */}
            <Grid container
                alignItems="center"
                justifyContent="center">
                <Grid item xs={11} sm={11} xl={11}>
                    <TableContainer component={Paper} sx={{ backgroundColor: 'secondary.main', borderRadius: '13px', padding: '30px' }}>
                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}
                            marginBottom={3}>
                            <Rules />
                        </Grid>
                        <Grid container spacing={1} marginBottom={1}
                            alignItems="flex-end"
                            justifyContent="center">
                            <Grid item xs={12} sm={9} lg={6}>
                                <Paper
                                    component="form"
                                    sx={{
                                        p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%',
                                        backgroundColor: 'secondary.main', borderRadius: '79px'
                                    }}
                                >
                                    <InputBase
                                        value={filterValue}
                                        sx={{ ml: 1, flex: 1, fontSize: '20px', fontWeight: '700' }}
                                        placeholder="Buscar..."
                                        inputProps={{ 'aria-label': 'buscar' }}
                                        onChange={event => { applyFilter(event.target.value, playersAll, false) }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </Grid>
                            <Grid item xs={3} lg={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Rules />
                            </Grid>

                        </Grid>

                        <Grid container spacing={1} marginBottom={1} marginTop={4}
                            alignItems="flex-end"
                            justifyContent="center">
                            <Grid item xs={12} sm={6} lg={2} marginBottom={1}>
                                <CustomButton onClick={() => onClickChangeTable('high')} 
                                    status={activeTable === "high" ? "active" : ""} 
                                    width="100%"
                                    variant="contained">HIGH ELO</CustomButton>
                            </Grid>


                            <Grid item xs={12} sm={6} lg={2} marginBottom={1}>
                                <CustomButton onClick={() => onClickChangeTable('low')} 
                                    status={activeTable === "low" ? "active" : ""} 
                                    width="100%"
                                    variant="contained">LOW ELO</CustomButton>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} marginBottom={1} marginTop={4}
                            direction="row"
                            alignItems="flex-end"
                            justifyContent="flex-end"
                            sx={{ justifyContent: { xs: 'center', lg: 'flex-end' } }}
                            >
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={showOnlineOnly} onChange={handleChangeShowOnline} name="ocultar-offline" />
                                    }
                                    label="Ocultar offline"
                                />
                        </Grid>

                        <Table aria-label="score table" >
                            <TableHead>
                                <TableRow>
                                    {/* <StyledTableCell align="center">
                                        <StyledTableSortLabel
                                            active={sortBy === 'number'}
                                            direction={sortBy === 'number' ? sortDir : 'asc'}
                                            onClick={handleRequestSort('number')}
                                        >
                                            #
                                            {sortBy === 'number' ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {sortDir === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </StyledTableSortLabel>
                                    </StyledTableCell> */}


                                    <StyledTableCell align="center">#</StyledTableCell>
                                    <StyledTableCell align="center">STREAMER</StyledTableCell>
                                    <StyledTableCell align="center">STREAM</StyledTableCell>
                                    <StyledTableCell align="center">CUENTA</StyledTableCell>
                                    <StyledTableCell align="center">ELO</StyledTableCell>

                                    {/* <StyledTableCell align="center">
                                        <StyledTableSortLabel
                                            active={sortBy === 'elo'}
                                            direction={sortBy === 'elo' ? sortDir : 'asc'}
                                            onClick={handleRequestSort('elo')}
                                        >
                                            ELO
                                            {sortBy === 'elo' ? (
                                                <Box component="span">
                                                </Box>
                                            ) : null}
                                        </StyledTableSortLabel>
                                    </StyledTableCell> */}

                                    <StyledTableCell align="center">PARTIDAS</StyledTableCell>
                                    <StyledTableCell align="center">V</StyledTableCell>
                                    <StyledTableCell align="center">D</StyledTableCell>
                                    <StyledTableCell align="center">WR%</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {playersShow.map((player) => (
                                    <TableRow
                                        hover
                                        key={player.nick_name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#F4F4F4 !important' } }}
                                    >
                                        <StyledTableCell component="th" scope="row" align="center">{player.pos}.</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Grid container
                                                alignItems="center"
                                                justifyContent="center">
                                                {/* <UserLogoWrapper src={player.twitch_avatar} alt={player.twitch} title={player.twitch} sx={{ display: { xs: 'none', lg: 'block' } }} /> */}
                                                {
                                                    player.twitch ?
                                                        <LinkWrapper href={`https://twitch.tv/${player.twitch}`} target="_blank" rel="noreferrer">{player.twitch}</LinkWrapper>
                                                        :
                                                        player.nick_name
                                                }
                                            </Grid>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Grid container
                                                alignItems="center"
                                                justifyContent="center">
                                                    {
                                                        player.twitch ?
                                                            <>
                                                                <LinkStatusWrapper href={`https://twitch.tv/${player.twitch}`} target="_blank" rel="noreferrer" isStreaming={player.is_streaming} sx={{ display: { xs: 'none', lg: 'block' } }}>{player.is_streaming ? "ONLINE" : "OFFLINE"}</LinkStatusWrapper>
                                                                <a href={`https://twitch.tv/${player.twitch}`} target="_blank" rel="noreferrer">
                                                                    <SlidesLogoWrapper src={slidesshow} alt="Logo" title="Status"  isStreaming={player.is_streaming} />
                                                                </a>
                                                            </> 
                                                            :
                                                            <>
                                                                <LinkStatusWrapper isStreaming={false} sx={{ display: { xs: 'none', lg: 'block' } }}>OFFLINE</LinkStatusWrapper>
                                                                <SlidesLogoWrapper src={slidesshow} alt="Logo" title="Status"  isStreaming={false} />
                                                            </>
                                                    }
                                                
                                            </Grid>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Grid container
                                                alignItems="center"
                                                justifyContent="center">
                                                {/* <UserLogoWrapper src={player.summoner_avatar} alt={player.nick_name} title={player.nick_name} sx={{ display: { xs: 'none', lg: 'block' } }} /> */}
                                                {player.nick_name}
                                            </Grid>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{`${player.tier} ${player.rank}`} <StyledEloScore>{`(${player.league_points} LP)`}</StyledEloScore></StyledTableCell>
                                        <StyledTableCell align="center">{player.total_games}</StyledTableCell>
                                        <StyledTableCell align="center">{player.wins}</StyledTableCell>
                                        <StyledTableCell align="center">{player.losses}</StyledTableCell>
                                        <StyledTableCell align="center">{player.win_rate}</StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {playersShow.length == 0 && 
                            <>
                                <Grid container spacing={1} marginBottom={1} marginTop={4}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    >
                                        <b>No se encontraron jugadores</b>
                                </Grid>
                            </>
                        }
                    </TableContainer>

                    <Grid container
                        alignItems="center" marginTop={3}
                        justifyContent="center"
                        sx={{ display: { xs: showAll ? 'none' : '' } }}
                    >
                        <Grid item xs={12} sm={8} md={6} lg={4} xl={2}>
                            <CustomButton onClick={onClickShowAll} variant="contained" status="active" width="100%">Ver más</CustomButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ScoreTableWrapper>
    )
};