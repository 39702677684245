import { styled } from "@mui/material/styles";
import tableLogo from '../../assets/2024/header-v3.png'
import { Grid } from "@mui/material";


const TableLogoWrapper = styled("img")((props) => ({
    height: "100%",
    maxHeight: "450x",
    width: "100%"
}));

export function MainTableHeader() {

    return (
        <Grid container spacing={1}
            alignItems="center"
            justifyContent="center">
            <Grid item xs={12}>
                <TableLogoWrapper src={tableLogo} alt="Ladder Race Omen Transcend 14" title="Ladder Race Omen Transcend 14" />
            </Grid>
        </Grid>
    )
}