import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { createGlobalStyle } from 'styled-components';
import { Header } from "./layouts/Header";
import { Footer } from "./layouts/Footer";
import MainTable from "./pages/MainTable/MainTable";

function App() {
  const GlobalStyle = createGlobalStyle`
    body {
      background: #FFFFFF;
    }
  `

  const theme = createTheme({
    typography: {
      "fontFamily": `"Poppins", "Helvetica", "Arial", sans-serif`
    },
    palette: {
      primary: {
        main: "#000000",
        light: "#c7dfff",
        dark: "#011783",
        contrastText: "#000",
      },
      secondary: {
        main: "#FFFFFF",
        light: "#fffbd1",
        dark: "#b3a832",
        contrastText: "#1D6BF5"
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <CssBaseline />
      <Header />
      <MainTable />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
