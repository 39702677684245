
import { styled } from "@mui/material/styles";
import tableLogo from '../../assets/2024/banner-v3.png'
import { Grid } from "@mui/material";


const TableFooterLogoWrapper = styled("img")((props) => ({
    height: "100%",
    maxHeight: "401px",
    width: "100%",
    marginTop: "50px",
    marginBottom: "100px"
}));

export function MainTableFooter() {

    return (
        <Grid container spacing={1}
            alignItems="center"
            justifyContent="center">
            <Grid item xs={11} sm={11} xl={11}>
                <TableFooterLogoWrapper src={tableLogo} alt="Gamers make better humans" title="Gamers make better humans" />
            </Grid>
        </Grid>
    )
}